@import url("https://fonts.googleapis.com/css?family=Fira+Code|Open+Sans|Red+Hat+Display&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  font-family: "Red Hat Display", sans-serif;
  font-family: "Fira Code", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffee93;
  background-attachment: fixed;
}

a {
  color: gray;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

a:hover {
  color: #79addc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  margin: 0 auto;
  grid-auto-rows: 4;
  padding-inline-start: 0px;
}

li {
  display: inline-block;
  vertical-align: top;
}
